export enum WheelchairFriendlyType {
  Ja = <any>'Ja',
  Nej = <any>'Nej'
}

export enum competitionCategoryFriendlyType {
  Kategori1 = <any>'kategori1',
  Kategori2 = <any>'kategori2',
  Kategori3 = <any>'kategori3',
  Kategori4 = <any>'kategori4'
}

export enum clubOpeningDaysFriendlyType {
  Mandag = <any>'Mandag',
  Tirsdag = <any>'Tirsdag',
  Onsdag = <any>'Onsdag',
  Torsdag = <any>'Torsdag',
  Fredag = <any>'Fredag',
  Loerdag = <any>'Lørdag',
  Soendag = <any>'Søndag'
}

export interface SysClubOpeningHours {
  id: null
  aabningstider_status: number // Should perhaps be boolean??
  aabningstider_aaben: string
  aabningstider_luk: string
  klubber_id: number
  aabningstider_dag: clubOpeningDaysFriendlyType
  aabningstider_kommentar: string
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysClubBypostId {
  id: null
  bypost_postnummer: number
  bypost_by: string
  created_at: string
  updated_at: string
}

export interface SysClubRegionId {
  id: null
  region_navn: string
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysClubMember {
  id: null
  medlem_status: boolean
  medlem_vejnummer: string
  medlem_foedselsdag: string
  medlem_begyndelse: string
  medlem_rolle: number
  medlem_licens: number
  medlem_sidste_kamp: string
  medlem_paradart: boolean
  medlem_karantaene: boolean
  medlem_karantaene_dato: string
  medlem_karantaene_periode: string
  bypost_id: SysClubBypostId
  // bruger_id: number
  user_id: number
  region_id: SysClubRegionId
  koen_id: number
  klubrolle: string
  klubber_id: number
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysClubEventsId {
  id: null
  event_status: boolean
  event_titel: string
  event_beskrivelse: string
  event_pris: number
  event_start: string
  event_slut: string
  event_sted: string
  event_invitation: string
  // bruger_id: number
  user_id: number
  klubber_id: number[]
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysClubCompetitionsId {
  id: null
  staevne_status: boolean
  staevne_navn: string
  staevne_kategori: competitionCategoryFriendlyType
  staevne_sidstetilmelding: string
  staevne_start: string
  staevne_slut: string
  staevne_spillested: string
  staevne_invitation: any
  // klubber_id: number
  staevne_raekker_id: number[]
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysClubTeamsId {
  id: null
  hold_status: number
  hold_holdnavn: string
  raekke_id: number
  // tuneringskategori_id: number
  published_at: string
  created_at: string
  updated_at: string
}

export default interface SysClub {
  id: null
  klubber_status: boolean
  klubber_klubnavn: string
  klubber_logo: string
  klubber_vejnavn: string
  bypost_id: SysClubBypostId
  region_id: SysClubRegionId
  klubber_telefonnummer: string
  klubber_email: string
  klubber_saldo: number
  klubber_klubbeskrivelse: string
  klubber_aaben: string // This is not in use.
  klubber_baner: number
  klubber_hjemmekampe: number
  klubber_etableringsaar: string
  klubber_koerestolsvenlig: WheelchairFriendlyType
  klubber_ansvarlig_id: SysClubMember
  hold_id: SysClubTeamsId[]
  events_id: SysClubEventsId[]
  staevner_id: SysClubCompetitionsId[]
  klubber_hjemmeside: string
  klubber_facebook: string
  klubber_slug: string
  klubber_longitude: number
  klubber_latitude: number
  aabningstiders_id: SysClubOpeningHours[]
  klubber_kontingent_slut: string
  published_at: string
  created_at: string
  updated_at: string
}
