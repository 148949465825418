import { Options, Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import BaseFunctions, { defaultMissingImgURL, LogoObj, mediaBaseURL } from '@/components/Utility/Base'
import ClubsDataService from '@/services/ClubsDataService'
import MembersDataService from '@/services/MembersDataService'
import OpeningHoursDataService from '@/services/OpeningHoursDataService'
import SysClub, { WheelchairFriendlyType } from '@/types/SysClub'
import SysMember from '@/types/SysMember'
import SysOpeningHours, { openingDaysFriendlyType } from '@/types/SysOpeningHours'
import { Quill, QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

type klubDataType = { klubId: number; klubAnsvarligId: number; klubFacebook: string; klubHjemmeside: string; klubStatus: boolean; klubLogo: string; klubKlubnavn: string; klubVejnavn: string; klubPostnummer: string; klubBy: string; klubRegion: string; klubTelefonnummer: string; klubEmail: string, klubBeskrivelse: string, klubAAben: string; klubHjemmekampe: string; klubBaner: string; klubEtableringsaar: string; klubKoerestolsvenlig: boolean; }
type medlemDataType = { brugerFornavn: string; brugerEfternavn: string; brugerTelefon: string; brugerEmail: string; medlemVejnummer: string; medlemPostnummer: string; medlemBy: string; }
type dataReturnType = { openingHours: any; medlemData: any; error: any; expandedAccordions: boolean[]; }

@Options({
  components: {
    QuillEditor
  }
})
export default class theClub extends Vue {
  private tempKlub = {} as SysClub
  private tempMedlem = {} as SysMember
  private openingHours: SysOpeningHours[] = []
  clubSlug = ''
  error: any = null
  klubData: klubDataType = { klubId: 0, klubAnsvarligId: 0, klubFacebook: '', klubHjemmeside: '', klubStatus: true, klubLogo: '', klubKlubnavn: '', klubVejnavn: '', klubPostnummer: '', klubBy: '', klubRegion: '', klubTelefonnummer: '', klubEmail: '', klubBeskrivelse: '', klubAAben: '', klubHjemmekampe: '1', klubBaner: '0', klubEtableringsaar: '', klubKoerestolsvenlig: false }
  medlemData: medlemDataType = { brugerFornavn: '', brugerEfternavn: '', brugerTelefon: '', brugerEmail: '', medlemVejnummer: '', medlemPostnummer: '', medlemBy: '' }

  readonly name : string = 'theClub'
  data (): dataReturnType {
    return {
      medlemData: this.medlemData,
      error: this.error,
      expandedAccordions: [true, true, true, false, false],
      openingHours: this.openingHours
    }
  }

  public convertOe (enumObj: Enumerator) : string {
    if (enumObj.toString() === 'Loerdag') {
      return 'Lørdag'
    } else if (enumObj.toString() === 'Soendag') {
      return 'Søndag'
    }

    return enumObj.toString()
  }

  declare $refs: {
    clubDescription: typeof QuillEditor
  }

  public showClubInfo () : void {
    this.clubSlug = ''
    let testId = 0
    const route = useRoute()
    this.clubSlug = route.params.slug.toString()
    console.log('Showing club info of club with id: ' + this.clubSlug)
    console.log('Test base media URL: ' + mediaBaseURL)

    ClubsDataService.getBySlug(this.clubSlug)
      .then((response) => {
        this.tempKlub = response.data[0]
        // console.log(response.data)

        if (this.tempKlub.klubber_ansvarlig_id === null || this.tempKlub.klubber_ansvarlig_id.id === null) {
          testId = 0
        } else {
          testId = Number(this.tempKlub.klubber_ansvarlig_id.id)
        }

        this.klubData = {
          klubId: Number(this.tempKlub.id),
          klubFacebook: this.tempKlub.klubber_facebook,
          klubHjemmeside: this.tempKlub.klubber_hjemmeside,
          klubStatus: this.tempKlub.klubber_status,
          klubLogo: this.tempKlub.klubber_logo,
          klubKlubnavn: this.tempKlub.klubber_klubnavn,
          klubVejnavn: this.tempKlub.klubber_vejnavn,
          klubPostnummer: (this.tempKlub.bypost_id.bypost_postnummer !== null ? this.tempKlub.bypost_id.bypost_postnummer.toString() : ''),
          klubBy: this.tempKlub.bypost_id.bypost_by,
          klubRegion: this.tempKlub.region_id.region_navn,
          klubTelefonnummer: (this.tempKlub.klubber_telefonnummer !== null ? this.tempKlub.klubber_telefonnummer.toString() : ''),
          klubEmail: this.tempKlub.klubber_email,
          klubBeskrivelse: this.tempKlub.klubber_klubbeskrivelse,
          klubAAben: this.tempKlub.klubber_aaben,
          klubHjemmekampe: (this.tempKlub.klubber_hjemmekampe !== null ? this.tempKlub.klubber_hjemmekampe.toString() : ''),
          klubBaner: (this.tempKlub.klubber_baner !== null ? this.tempKlub.klubber_baner.toString() : ''),
          klubEtableringsaar: (this.tempKlub.klubber_etableringsaar === null ? '' : this.tempKlub.klubber_etableringsaar),
          klubKoerestolsvenlig: (this.tempKlub.klubber_koerestolsvenlig === WheelchairFriendlyType.Ja),
          klubAnsvarligId: testId
        }
        console.log('KlubId: ' + this.klubData.klubId)
        const interval = setInterval(() => {
          if (this.$refs.clubDescription) {
            clearInterval(interval)
            this.$refs.clubDescription.setContents(this.klubData.klubBeskrivelse, 'user')
          }
        }, 100)
        if (this.klubData.klubAnsvarligId !== 0) {
          this.getMember(this.klubData.klubAnsvarligId)
        }
      })
      .catch((err) => {
        this.error = err
      })
      .then(() => {
        OpeningHoursDataService.getAll('', null, `klubber_id.id=${this.klubData.klubId.toString()}`)
          .then((response) => {
            this.openingHours = response.data

            if (this.openingHours.length > 1) {
              const weekdayOrder = Object.values(openingDaysFriendlyType)
              this.openingHours = this.openingHours.sort(function (a, b) {
                return weekdayOrder.indexOf(a.aabningstider_dag) - weekdayOrder.indexOf(b.aabningstider_dag) || a.aabningstider_aaben.localeCompare(b.aabningstider_aaben)
              })
              // this.openingHours = this.openingHours.sort((a, b) => weekdayOrder.indexOf(a.aabningstider_dag) - weekdayOrder.indexOf(b.aabningstider_dag))
            }

            console.log('Sorted Opening Hours for ' + this.klubData.klubKlubnavn + '(' + this.klubData.klubId + '): ' + JSON.stringify(this.openingHours))
          })
          .catch((err) => {
            this.error = err
          })
      })

    // if (testId !== 0) {
    //   this.getMember(testId)
    // }
  }

  public getMember (medlemId : number) : void {
    console.log('Fetching Member info from member with id: ' + medlemId)
    MembersDataService.get(medlemId.toString())
      .then((response) => {
        this.tempMedlem = response.data
        // console.log(response.data)

        this.medlemData = {
          brugerFornavn: this.tempMedlem.user_id.firstname,
          brugerEfternavn: this.tempMedlem.user_id.lastname,
          brugerTelefon: this.tempMedlem.user_id.phone.toString(),
          brugerEmail: this.tempMedlem.user_id.email,
          medlemVejnummer: this.tempMedlem.medlem_vejnummer.toString(),
          medlemPostnummer: (this.tempMedlem.bypost_id !== undefined && this.tempMedlem.bypost_id.bypost_postnummer !== undefined && this.tempMedlem.bypost_id !== null && this.tempMedlem.bypost_id.bypost_postnummer !== null ? this.tempMedlem.bypost_id.bypost_postnummer.toString() : ''),
          medlemBy: (this.tempMedlem.bypost_id !== null && this.tempMedlem.bypost_id.bypost_by !== null ? this.tempMedlem.bypost_id.bypost_by : '')
        }

        console.log('getMember: ' + this.medlemData.brugerEmail)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public extractLogoURLFromClubLogoData (clubLogoData: any) : string {
    let logoURL = defaultMissingImgURL

    if (clubLogoData !== null) {
      const logoObj = BaseFunctions.setLogoMediaObjFromObj(<any> clubLogoData) as LogoObj[]
      if (logoObj[0] !== undefined && logoObj[0].url !== null && logoObj[0].formats !== undefined && logoObj[0].formats.thumbnail !== undefined && logoObj[0].formats.thumbnail.url !== undefined && logoObj[0].formats.thumbnail.url !== null) {
        logoURL = mediaBaseURL + logoObj[0].formats.thumbnail.url
      } else {
        logoURL = logoObj[0] === undefined || logoObj[0].url === null ? logoURL : mediaBaseURL + logoObj[0].url
      }
    }

    return logoURL
  }

  async mounted () : Promise<void> {
    this.showClubInfo()
  }
}
