
import { Options, Vue } from 'vue-class-component'
import theClub from '@/components/Frontend/Clubs/index.vue'

@Options({
  components: {
    theClub
  }
})
export default class ClubInfo extends Vue {}
